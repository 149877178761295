.editor {
  text-align: left;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2px;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor .public-DraftEditor-content {
  min-height: 80px;
}

.insertImageWrapper {
  display: inline-block;
}

.insertImageButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.insertImageButton:hover,
.insertImageButton:focus {
  background: #f3f3f3;
}

.draftJsFocusPlugin__unfocused__1Wvrs:hover {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #d2e3f7;
}

.draftJsFocusPlugin__focused__3Mksn {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #accef7;
}

.DraftEditor-root {
  margin-top: 10px;
}
